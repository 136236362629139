@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.App {
  text-align: center;
  font-family: "DM Sans", sans-serif;
 
}
a{
  text-decoration: none !important;
  color: inherit ! Important;
  display: Block;
}
.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.App-header {
  Background-color: #ffffff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(37, 37, 37);
  margin-top: 15vh;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.formInput {
  width: 100%;
}
.row{
  margin: 0!important;
   
}
.logotext {
  font-size: 3vw;
  /* font-family: "Noto Sans", sans-serif; */
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: #61c7eb;
}
.searchInput {
  border: 1px solid #dfe1e5;
  border-radius: 30px;
  width: 100%;
  padding: 10px 20px 10px 50px;
  font-size: 18px;
  transition: 0.3s;
  font-family: "DM Sans", sans-serif;
}
.searchInputDiv{
  width: 80%;
  position: relative;
}
.searchIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  left: 15px;
  color: #9aa0a6;
}
:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  transition: 0.3s;
}

.searchInput:focus-visible, .searchInput:hover{
  box-shadow: 0 1px 6px rgba(32,33,36,.28);
  border: 1px solid transparent;
  transition: 0.3s;
}
.saferText {
  font-weight: 400;
  font-size: 16px;
  font-family: 'DM Sans';
  margin-top: 15px;
  color: 3c4043;
  background: #f8f9fa;
  padding: 5px 20px;
  border-radius: 3px;
}
.footer {
  Background: #f2f2f2;
  padding: 10px;
  border-top: 1px solid #dbdbdb;
}
.minHeightRow {
  min-height: 93.9vh;
}
.footerPtext{
  margin: 0;
  padding: 0;
}
ul.footerMenu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: end;
}
.footerMenu li a {
  margin: 0px 15px;
  text-decoration: none;
  color: #000000;
  font-size: 16px;
}
.copyRight{
  text-align: left;
}
.partner-form {
  text-align: left;
}
@media (max-width: 768px){
.searchInputDiv{
  width: 100%;
}
.mobileRtL {
  flex-direction: column-reverse;
}
ul.footerMenu{
  justify-content: space-around;
}
.copyRight{
  text-align: center;
}
.footerMenu li a {
  font-size: 14px;
}
.copyRight{
  font-size: 14px;
}
.App-header{
  margin-top: 0vh;
}
.App-logo {
  height: 30vmin;
 
}
.logotext{
  font-size: 15vw;
}
}